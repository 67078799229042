@import "../../context/theme/general.scss";
@import "../../context/theme/constants.scss";


.Breadcrumbs {
    position: absolute;
    z-index: 2;
    top: $header-height;
    flex-direction: row-reverse;
    flex-wrap:  wrap-reverse;
    
    & > &-Crumb {
        &:not(:last-child) {
            margin-left: -3.9em;
        }
        filter: drop-shadow(5px 0px 1px rgba(0,0,0,.3));

        a {
            @extend .color-1;
            padding: 1em 4em;
            clip-path: polygon(0% 0%, 100% 0%, calc(100% - 4em) 100%, 0% 100%);
            display: inline-block;
            @extend .undecorated;
            text-transform: capitalize;
            filter: drop-shadow(5px 1px 3px white);
            &:first-child { z-index: 5; }

            &:hover {
                color: nth($list: $color-5, $n: 2);
                background-color: nth($list: $color-2, $n: 2);
            }
        }

        &:first-child {
            a {
                color: nth($list: $color-4, $n: 2);


                &:hover {
                    color: nth($list: $color-5, $n: 2);
                }
            }
        }
    }
}