.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; 
  outline: none;

  &:hover {
    opacity: 0.7; 
  }
  &.is-active:hover {
    opacity: 0.7; 
  }
  &.is-active &-inner,
  &.is-active &-inner::before,
  &.is-active &-inner::after {
    background-color: #DDD; 
  }

  &-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
   }

  &-inner {
    display: block;
    top: 50%;
    margin-top: -2px; 
    &, &::before, &::after {
      width: 40px;
      height: 4px;
      background-color: #DDD;
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
     }
    &::before, &::after {
      content: "";
      display: block; 
    }
    &::before {
      top: -10px;
     }
    &::after {
      bottom: -10px; 
    }
  }
  &--emphatic {
    overflow: hidden; 
  }
  &--emphatic &-inner {
    transition: background-color 0.125s 0.175s ease-in; 
  }
  &--emphatic &-inner::before {
    left: 0;
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; 
  }
  &--emphatic &-inner::after {
    top: 10px;
    right: 0;
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
  }
  &--emphatic.is-active &-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important;
  }
  &--emphatic.is-active &-inner::before {
    left: -80px;
    top: -80px;
    transform: translate3d(80px, 80px, 0) rotate(45deg);
    transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  &--emphatic.is-active &-inner::after {
    right: -80px;
    top: -80px;
    transform: translate3d(-80px, 80px, 0) rotate(-45deg);
    transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
}