@import "./constants.scss";

.flex {
  display: flex;

  &.space-around {
    justify-content: space-around;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.space-evenly {
    justify-content: space-evenly;
  }

  &.align-center {
    align-items: center;
  }

  &.justify {
    &-start {
      justify-content: start;
    }
    &-center {
      justify-content: center;
    }
    &-end {
      justify-content: end;
    }
  }

  &.center {
    align-items: center;
    justify-content: center;
  }

  &.column {
    flex-direction: column;
  }

  &.wrap {
    flex-wrap: wrap;
    &-reverse {
      flex-wrap: wrap-reverse;
    }
  }
}

.pad {
  @each $p in $paddings {
    &-px-#{$p} {
      padding: 0 #{$p}px;
    }
    &-pc-#{$p} {
      padding: 0 #{$p}#{"%"};
    }
  }
  @for $i from 1 to 11 {
    &-vw-#{$i} {
      padding-left: #{$i}#{"vw"};
      padding-right: #{$i}#{"vw"};
    }
    &-vh-#{$i} {
      padding-top: #{$i}#{"vh"};
      padding-bottom: #{$i}#{"vh"};
    }
  }
}

.margin {
  @for $i from 1 to 11 {
    &-vw-#{$i} {
      margin-right: #{$i}#{"vw"};
      margin-left: #{$i}#{"vw"};
    }
    &-vh-#{$i} {
      margin-top: #{$i}#{"vh"};
      margin-bottom: #{$i}#{"vh"};
    }
    &-v-#{$i} {
      margin: #{$i}#{"vh"} #{$i}#{"vw"};
    }
  }
}

@each $i, $c, $tc in $colors {
  .color-#{$i} {
    background-color: $c;
    color: $tc;
  }
} 

a {
  &.undecorated {
    outline: none;
    text-decoration: none;
    color: inherit;
  }
}

ul {
  &.undecorated {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin: 0;
      padding: 0;
    }
  }

  @each $p in $paddings {
    &.pad-items-#{$p}px > li {
      padding-left: #{$p}px;
      padding-right: #{$p}px;
    }
    &.pad-items-#{$p}pc > li {
      padding-left: #{$p+"%"};
      padding-right: #{$p+"%"};
    }
  }

  @for $i from 1 to 11 {
    &.pad-items-#{$i}vw > li {
      padding-left: #{$i+"vw"};
      padding-right: #{$i+"vw"};
    }
  }
}

.ws-no-break {
  white-space: nowrap;
}

.buttonlike {
  cursor: pointer;
}

.block {
  display: block;
}

.hover {
  &-lighten:hover {
    filter: grayscale(50%);
  }
}

.fullsize {
  height: 100%;
  width: 100%;
}
.fullwidth {
  width: 100%;
}
.fullscreenwidth {
  width: 100vw;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

.bold {
  font-weight: bold;
}

@for $i from 1 to 6 {
  .z#{$i} {
    z-index: $i;
  }
}

a.active {
  color: nth($color-3, 2);
}

.text {
  &-start { text-align: start; }
  &-end { text-align: end; }
  &-center { text-align: center; }
  &-justify { text-align: justify; }
}

img {
  max-width: 100%;
}

p, article {
  text-align: justify;
}


.Paper {
  margin: 10px;
  padding: 10px;
  background: transparentize(nth($color-1, 2), $amount: .6);
  border-radius: 3px;
  box-shadow: 3px 3px 5px transparentize(nth($color-1, 2), .6) ;
}

.button {
  background-color: nth($color-1, 2); /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 0 4px 8px 0 rgba(0,0,0,0.19);
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  &:active {
    transform: translate(2px, 2px);
  }
}

@keyframes fadeIn {
  from { opacity: 0 }
  to { opacity: 1 }
}

.shadowed {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  &-hover:hover {
    transform: translate(-3px, -3px);
    box-shadow: 3px 4px 10px 0 rgba(0, 0, 0, 0.2), 3px 6px 25px 0 rgba(0, 0, 0, 0.19);
  }
}

$NestedTable-Border-Color: nth($list: $color-4, $n: 2);
.Nested-Table {
    border: 1px solid $NestedTable-Border-Color;
    border-collapse: collapse;
    margin: 1em 0;

    &.Paper {
        padding: 0;
        // margin: 0;
    }

    th, td {
        padding: 1rem;
        text-align: start;
    }

    .Leading-Row {
        // border: 1px solid $NestedTable-Border-Color;
        border-top: 1px solid $NestedTable-Border-Color;
    }

    .Leading-Cell {
      vertical-align: top;
    }

    @media screen and (max-width: $breakpoint-m) {
        font-size: .7rem;
    }
}

.font-big {
  font-size: 1.2rem;
}

.font-shadowed {
  // filter: drop-shadow(1.5px 1.5px 1px black);
  text-shadow: 1px 2px 1.5px rgba(0,0,0,0.5);
}

$fade-duration: 500ms;

.fade-enter {
    opacity: 0;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity $fade-duration ease-in;
}

.fade-exit {
    opacity: 1;
    display: none;
    // position: absolute;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity $fade-duration ease-in;
    display: none;
    // position: absolute;
}