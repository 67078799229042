@import "../../context/theme/constants.scss"; 

$default-thickness: 2px;

.Separator {
  display: flex;
  justify-content: center;
  align-items: center;

  &::before, &::after {
    content: "";
    opacity: .5;
    background: nth($color-1, 3);
  } 

  &.horizontal {
    width: 80%;
    margin: 1vw auto;
    min-height: 1em;
    
    &::before, &::after {
      height: $default-thickness;
      width: 100%;
      margin: auto 2vw;
    } 
    &:empty {
      &::before {
        margin-right: 0;
      }
      &::after {
        margin-left: 0;
      }
    }
  }

  &.vertical {
    height: 80%;
    margin: auto 1vw;
    min-width: 1em;
    flex-direction: column;

    &::before, &::after {
      width: $default-thickness;
      height: 100%;
      margin: 1vh auto;
    } 
    &:empty {
      &::before {
        margin-bottom: 0;
      }
      &::after {
        margin-top: 0;
      }
    }
  }
}