@import "../../context/theme/constants.scss";

footer {
  width: 100vw;
  padding: 2vh 2vw;
  box-shadow: 0 -2px 10px black;
  text-align: center;

  @media screen and (max-width: $breakpoint-l) {
    flex-direction: column-reverse;
  }

  > div {
    margin: 1vh 1vw;
    flex: 1;
  }

  address {
    font-size: smaller;
    font-style: normal;
    text-align: start;
    @media screen and (max-width: $breakpoint-l) {
      text-align: center;
    }
  }
}

#socials {
    > a {
      margin: .5vh .5vw;
    }
}