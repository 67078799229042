@import "../../../../context/theme/constants.scss";
@import "../../../../context/theme/general.scss";

#Kontakt {
    td:first-child {
        text-align: end;
        padding-right: 20px;
    }

    table {
        border-spacing: none;
    }
    th, tr, td {
        padding: 0;
    }
}

.KontaktFormular {
    background: white;
    color: nth($list: $color-3, $n: 2);
    text-align: center;
    width: 100%;
    max-width: 90vw;

    > form {
        $gap: 2rem;
        margin: $gap;
        display: grid;
        grid-template-columns: repeat(auto-fit, calc(50% - #{$gap}));
        grid-auto-flow: row;
        gap: $gap;

        label {
            color: nth($list: $color-3, $n: 2);
        }

        button {
            grid-column: 1 / -1;
            background: nth($list: $color-3, $n: 2);
            color: nth($list: $color-3, $n: 3);
            margin: 1rem 0;

            &:hover {
                background: nth($list: $color-3, $n: 2);
                color: nth($list: $color-3, $n: 3);
            }
        }
    }
}

.leaflet-container {
    z-index: 1;
    img {
        box-shadow: none;
    }
}

.Wegbeschreibung {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;

    > div {
        max-width: 475px;
        margin: 1em auto;
        padding: 0 1em;
        @extend .Paper;
    }
}