@import "../../context/theme/general.scss";

.Download {
    @extend .undecorated;
    @extend .buttonlike;
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    margin: 2px;
    background: white;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    height: 2em;
    align-items: center;
    display: grid;
    grid-template-columns: 2em 1fr;

    &-pdf {
        @extend .Download;
        background: rgb(184, 3, 3);
    }

    > img {
        max-height: 100%;
        display: inline;
    }
}