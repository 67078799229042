@import "../../../../context/theme/constants.scss";

.FrontPage-Nav {
    margin-top: 1rem;
}

.kwl-colors {
    @extend .background-filled;
    background: linear-gradient(
        to bottom right,
        nth($color-3, 2) 20%,
        #ffffff,
        #abbaab 80%,
        #abbaab
    );

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background: initial;
    }
}

.background-filled {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.slogan {
    filter: drop-shadow(1px 1px 1px #ddd) drop-shadow(2px 2px 1px #000);
}
