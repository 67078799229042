@import "../../context/theme/constants.scss";

$control-button-size: 15px;
$play-button-size: 200px;
$button-transition-duration: 500ms;

.Slider {
  overflow: hidden;
  max-height: 100vh;
  position: relative;

  &-PlayButton {
    transform: translate(-50%, -50%);
    z-index: 1;
    position: absolute;
    border-radius: 50%;
    width: $play-button-size; height: $play-button-size;
    border: 5px solid nth($list: $color-4, $n: 2);
    background-color: nth($list: $color-3, $n: 2);
    cursor: pointer;
    outline: none;
    opacity: 0;
    &:hover {
      opacity: 1;
    }

    @media screen and (max-width: $breakpoint-l) {
      width: .5 * $play-button-size; height: .5 * $play-button-size;
    }

    transition: $button-transition-duration all ease;
    
    
    &::after{
      content: "";
      position: absolute;
      transform: translate(-50%, -50%);
      background-color: nth($list: $color-3, $n: 3);
      width: .5 * $play-button-size; height: .5 * $play-button-size;
      transition: height $button-transition-duration ease, width $button-transition-duration ease;
      @media screen and (max-width: $breakpoint-l) {
        width: .25 * $play-button-size; height: .25 * $play-button-size;
      }
    }
    
    &.playing {
      &::after{
        clip-path: none; 
        background-image: linear-gradient(to right,white 33%, #{nth($color-3, 2)} 34%, #{nth($color-3, 2)} 66%, white 67%, white);
      }
    }
    $transitiion-delay: 750ms;
    &.paused {
      transition-delay: $transitiion-delay;
      -webkit-transition-delay: $transitiion-delay;
      -moz-transition-delay: $transitiion-delay;
      opacity: .6;
      &:hover {
        opacity: .9;
      }
      &::after{
        clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
        transform: translate(-40%, -50%);
      }
    }
    &.error {
      opacity: .7;
      background-color: rgba(255, 0, 0, 0.562);
      &::after {
        clip-path: polygon(10% 0%, 50% 40%, 90% 0%, 100% 10%, 60% 50%, 100% 90%, 90% 100%, 50% 60%, 10% 100%, 0% 90%, 40% 50%, 0% 10%);
      }
    }
    &.loading {
      transition-delay: $transitiion-delay;
      -webkit-transition-delay: $transitiion-delay;
      -moz-transition-delay: $transitiion-delay;
      opacity: .7;
      &::after {
        border-radius: 50%;
        border: 2px solid nth($list: $color-4, $n: 3);
        border-top: 2px solid nth($list: $color-4, $n: 2);
        background-color: transparent;
        transform-origin: top left;
        animation: Spinner infinite 1s;

        @keyframes Spinner {
          from {
            transform: rotate(0deg) translate(-50%, -50%);
          }
          to {
            transform: rotate(360deg) translate(-50%, -50%);
          }
        }
      }
    }

    &.playing {
      opacity: 0;
    }

  }
  &:hover &-PlayButton.playing {
    opacity: .5;
    @media (hover: none) {
      opacity: 0;
    }
  }

  &.with-controls {
    @media screen and (max-width: $breakpoint-l) {
      display: block!important;
      padding-bottom: 2.2em;
    }
  }

  div {
    width: 100%;
  }

  > div.controls {
    position: absolute;
    bottom: 2vh;
    width: 100%;
    @media screen and (max-width: $breakpoint-l) {
      position: relative;
      top: 100%;
    }
    
    > input[type="radio"] {
      height: $control-button-size; width: $control-button-size;
      margin: 1rem 1.5rem;
      padding: 0;
      border-radius: 100%;
      background: white;
      border: none;
      box-shadow: 1px 1px rgba(0,0,0,.5);
      opacity: .5;
      appearance: initial;
      display: block;
      outline: none!important;
      transition: .25s all linear;
      flex-shrink: 0;

      &.active {
        // zoom: 1.5;
        transform: scale(1.5);
        opacity: .9;
      }
    }

  }
  
  img, video {
    width: 100%;
    max-height: 100%;
  }

  video {
    object-fit: cover;
    cursor: pointer;
  }

  img {
    object-fit: contain;
    filter: drop-shadow(2px 2px 3px black)
  }
}