@import "../../context/theme/constants.scss";
@import "../../context/theme/general.scss";

header {
  height: $header-height;
  position: fixed;
  width: 100vw;
  top: 0;
  box-shadow: 0 2px 10px black;
  box-sizing: border-box;

  img {
    height: $header-height;
    width: $header-height;
  }
  h1 {
    margin: 0;
    white-space: nowrap;
    color: nth($color-1, 3);
  }
  nav {
    a:hover {
      color: nth($color-4, 2);
    }
    a:active {
      color: nth($color-5, 2);
    }

    > ul, .fix-container > ul {
      a, span {
        height: $header-height;
        @extend .flex;
        @extend .center;
        @extend .buttonlike;
        user-select: none;
        word-wrap: wrap;
      }
      a, ul, li {
        @extend .undecorated;
      }
    }

    &.thin {
      > .fix-container {  
        position: fixed;
        left: 0; 
        top: $header-height;
        height: calc(100% - #{$header-height});
        width: 100vw;
        overflow-y: auto;
        
        > ul {   
          a, span {
            width: 100%;
            justify-content: start!important;
            padding: 0 10vw;
          }
  
          ul {
            text-indent: 10vw;
            li::before {
              content: "";
              position: absolute;
              display: block;
              height: $header-height;
              left: 0;
              border-width: 0 3px 3px 0;
              border-color: nth($color-2, 2);
              border-style: solid;
              border-radius: 3px;
            }
          }
  
          > li {
            &::before , &::after {
              height: 1px;
              background: radial-gradient(#{nth($color-3, 2)}, #{nth($color-2, 2)} , #{nth($color-1, 2)} 50%);
              content: "";
              display: block;
            }
          }
        }
      }
    }

    &.wide {
      li {
        @extend .color-1;
        &::after {
          $size: 3px;
          display: block;
          content: "";
          margin-top: -$size;
          border-bottom: $size solid nth($color-4, 2);
          width: 100%;
          transform: scaleX(0);
          transition: transform .25s ease-in-out;
        }
        &:hover {
          &::after {
            transform: scaleX(1);
          }
        }
      }
      > ul {
        > li {
          a { 
            @extend .pad-vw-1;
            overflow-x: hidden;
          }

          > div > ul { // first dropdown
            position: absolute;
            @extend .color-1;
          }
        }
      }
    }
  }
}

.header-fix {
  margin-top: $header-height
}