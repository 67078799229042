@import "../../../../context/theme/constants.scss";

.Person {
    &-Card.MuiCard-root { 
        box-shadow: 4px 4px 0 nth($list: $color-4, $n: 2);
        width: 100%;
    }

    &-Header {
        color: nth($list: $color-1, $n: 2);
    }

    &-Card img.MuiCardMedia-img {
        max-height: 248.97px;
        overflow: hidden;
    }

    &-Rows {
        > li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            > p {
                line-height: 180%;
                margin-left: 1em;
                text-shadow: none;
                > a {
                    color: nth($color-3, 2);
                }
            }
        }
    }
}