@import "../../context/theme/constants.scss";
@import "../../context//theme//general.scss";

$LinkCard-height: 200px;
$LinkCard-width: 400px;

.LinkCard {
    border: 1px solid nth($list: $color-4, $n: 2);
    height: $LinkCard-height;
    width: $LinkCard-width;
    margin: 2vh 0; 

    &:hover span {
        height: 100%;
    }

    &-Container {
        position: relative;

        > span {
            position: absolute;
            left: 0;
            bottom: 0;
            background: transparentize($color:  nth($list: $color-3, $n: 2), $amount: .2);
            width: 100%;
            font-size: 1.5em;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 2em;
            transition: .5s all ease;
        }
    }
}