@import "../../context/theme/constants.scss";
@import "../../context/theme/general.scss";

main {
  flex-grow: 1;
  color: nth($color-2, 3);
  padding: 2vh 0;
  max-width: 100%;
  min-height: calc(100vh - #{$header-height});

  > div > section > div {
    width: 60%;
    @media screen and (min-width: $breakpoint-m) and (max-width: $breakpoint-l) {
      width: 80%;
    }
    @media screen and (max-width: $breakpoint-m) {
      width: 90%;
    }
    margin: 0 auto;
    text-align: center;
    li {
      text-align: initial;
    }
  }

  p, article, /*h1, h2, h3, h4, h5, h6,*/ li  {
    @extend .font-shadowed;
    @extend .font-big;
    line-height: 180%;
    text-rendering: optimizeLegibility;
  }
  img {
    @extend .shadowed;
  }
}

a {
  color: nth($list: $color-4, $n: 2);
  &:visited {
    color: nth($list: $color-3, $n: 2);
  }
}