@import "./context/theme/constants.scss";
.App {
  width: 100vw;
  // height: 100vh; causes autoscroll issues (mouse gesture)
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

.Background {
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -1;
  background-image: radial-gradient(
    ellipse,
    nth($color-2, 2) 40%,
    nth($color-1, 2) 100%
  );
  background-repeat: no-repeat;
  background-position: center;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: url("./static/bg_content.png");
    background-repeat: no-repeat;
    background-position: center;
    filter: drop-shadow(3px 3px black) opacity(0.1);
    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      /* IE10+ specific styles go here */
      opacity: 0.1;
    }
  }
}

.Job {
  h5,
  h6 {
    text-align: start;
  }

  section {
    > h6 {
      color: nth($color-3, 2);
      ~ * {
        padding-left: 1ch;
        padding-right: 1ch;
      }
    }
  }
  a {
    color: blue;
    text-decoration: none;
  }

  &_Title {
    background-color: nth($color-4, 2);
    padding: 1ch;
  }

  p,
  li {
    font-size: 1em;
  }
  ul {
    padding: 0 1em;
    > ul {
      padding: 0 3ch;
    }
  }
  li {
    padding: 0;
    margin: 0;
  }
  &_Image {
    max-height: 320pt;
    @media screen and (max-width: $breakpoint-l) {
      max-height: 160pt;
    }
  }
}
.Job,
.JobPreview {
  max-width: 90vw;
  p,
  li,
  span,
  div {
    text-shadow: none;
  }

  &_Meta {
    margin-top: 1em;
    padding: 1ch;
    background: nth($color-4, 2);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  &_Image {
    display: block;
    width: 99%;
    object-fit: cover;
    margin: 1em auto;
  }
}

.JobPreview {
  min-width: minmax(70ch, 100%);

  &_Image {
    max-height: 200pt;
    @media screen and (max-width: $breakpoint-l) {
      max-height: 80pt;
    }
  }
}
