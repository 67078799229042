@import "../../../../context/theme/general.scss";
// @import "../../../../context/theme/constants.scss";

.tab-controls {
    .button {
        &.active {
            // background-color: green;
            // color: white;
            @extend .color-4;
        }
        color: inherit;    
        h4, h5, h6 {
            margin: 0;
        }
    }
}

