@import "../../context/theme/constants.scss";

.pdf {
    h2 {
        @media screen and (max-width: $breakpoint-s) {
            font-size: 1.3rem;
        }
    }

    animation: fadeIn .5s;

    &-title {
        text-align: start;
        h2:not(:only-child) {
            margin-bottom: 0;
        }
        h4 {
            opacity: .8;
            margin-top: 0;
        }
    }

    &.hidden {
        display: none;
    }

    &-pages {
        height: 1280px;
        > img {
            margin: 1vh 1vw;
        }
    }
}