/* color palette */
/* dark */
$color-1: 1 #202020 #ddd;
$color-2: 2 #3a3a3a #ddd;
// $color-3: 3 #007f3e #DDD;
// $color-3: 3 #009981 #DDD;
// $color-3: 3 #009A9B #DDD;
// $color-3: 3 #00A995 #DDD;
$color-3: 3 darken(#00a995, 11) #ddd;
// $color-4: 4 rgb(42, 172, 83) #DDD;
$color-4: 4 #bee2e9 #3a3a3a;
$color-5: 5 #c6ddc9 #3a3a3a;
// $color-5: 5 #c6ddc9 #3A3A3A;

/* light */
// $color-5: 5 #202020 #DDD;
// $color-4: 4 #3A3A3A #DDD;
// $color-3: 3 #007f3e #DDD;
// $color-2: 2 #fff #000;
// $color-1: 1 #fff rgb(3, 99, 11);

$colors: $color-1, $color-2, $color-3, $color-4, $color-5;

$paddings: 5, 10, 15, 20;
$breakpoint-xs: 0px;
$breakpoint-s: 376px;
$breakpoint-m: 426px;
$breakpoint-l: 769px;
$breakpoint-xl: 1025px;

$main-padding-lr: 10vw;
$header-height: 64px;
