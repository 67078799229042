@import "../../../../context/theme/constants.scss";
@import "../../../../context/theme/general.scss";

$top: calc(10vh + #{$header-height});

.History {
    position: initial;
    &-Timeline {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        position: fixed;
        left: 5%;
        top: $top;
        height: 50vh;
        width: 25%;
        &-Item {
            @extend .buttonlike;
            text-align: start;
            display: grid;
            grid-template-columns: auto 1fr;

            > span {
                margin: 0 1em;
            }

            &.focus { 
                font-weight: bold;
            }
        }
    }
    &-Content {
        position: absolute;
        left: 35%;
        width: 60%;
        top: $top;
        padding-top: 15vh;
        &-Item {
            @extend .shadowed;
            margin-bottom: 10vh;
            transition: .5s transform ease;
            &.focus {
                transform: scale(1.05);
            }
            &-Text {
                padding: 1em;
            }
            background-color:  transparentize($color: nth($color-1, 2), $amount: .4) ;

            .Slider {
                padding-top: 1em;
            }
        }
        .placeholder {
            height: 20vh;
            width: 100%;
            background: rgba(0,0,0,.5);
            opacity: .4;
            margin: 0;
        }
        img {
            width: 100%;
        }
    }
}

.HistoryMobile {
    &-Timeline {
        z-index: 1;
        position: fixed;
        top: $header-height;
        left: 0;
        // height: 80px;
        max-width: 100vw;
        overflow: hidden;


        &-Index {
            box-sizing: border-box;
            padding-left: 2rem;
            padding-right: 2rem;
            display: grid;
            grid-template-columns: 64px 1fr;
            height: 100%;
            gap: 1rem;

            > h4 {
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

    }
    &-Content {
        &-Item {
            background-color:  transparentize($color: nth($color-1, 2), $amount: .4) ;
            &-Text {
                font-size: 1rem;
            }
        }
    }
    &-Marker {
        position: fixed;
        width: 2vw;
        opacity: .5;
        height: 2px;
        background: white;
        left: 0;
    }
}