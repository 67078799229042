.ImageText {
    .center {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
    }

    p.MuiTypography-paragraph {
        max-width: 100%;
    }
}